
#about .row:first-child {
  margin-top:0px;
}
#about .row {
  margin-top:40px;
}
#about .map {
  min-height:200px;
  height:100%;
}
#about .details .contact address {
  margin-bottom:20px;
}
#about .details .contact address span {
  display: inline-block;
  padding-top: 5px;
}
#about .details .contact .contacts {
  margin-top:30px;
}
#about .details .contact .contacts > div {
  margin-top:10px;
}
@media (max-width: 767.98px) {
#about .details {
    padding:3rem;
}
#about .details .contact,
  #about .details .contact .contacts {
    display: flex;
    flex-wrap: wrap;
}
#about .details .contact address,
  #about .details .contact > div,
  #about .details .contact .contacts > div {
    flex: 1 0 auto;
    margin-right:30px;
}
#about .details .contact .contacts {
    flex:auto;
    margin-right:0px;
    margin-top:20px;
}
#about .map {
    margin-top:20px;
}
}
#about .identifiers, #about .social-medias {
  margin-top:20px;
}
    


